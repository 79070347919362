import React from "react";
import Icon from "@ant-design/icons";

const StopIconSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M10 0.200195C4.62806 0.200195 0.25 4.5109 0.25 9.8002C0.25 15.0895 4.62806 19.4002 10 19.4002C15.3719 19.4002 19.75 15.0895 19.75 9.8002C19.75 4.5109 15.3719 0.200195 10 0.200195ZM10 2.33353C11.708 2.33353 13.2756 2.88965 14.5407 3.82103L3.92741 14.271C2.98148 13.0254 2.41667 11.4819 2.41667 9.8002C2.41667 5.66384 5.79901 2.33353 10 2.33353ZM16.0726 5.32936C17.0185 6.57497 17.5833 8.11844 17.5833 9.8002C17.5833 13.9366 14.201 17.2669 10 17.2669C8.29197 17.2669 6.72438 16.7107 5.45931 15.7794L16.0726 5.32936Z"
      fill="#FF0000"
    />
  </svg>
);

const StopIcon = (props: any) => <Icon component={StopIconSvg} {...props} />;

export default StopIcon;
