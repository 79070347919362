import moment from "moment";

export interface IISOToLocale {
  weekday?: "long" | "short" | "narrow" | undefined;
  day?: "numeric" | "2-digit" | undefined;
  year?: "numeric" | "2-digit" | undefined;
  month?: "long" | "short" | "narrow" | "numeric" | "2-digit" | undefined;
  hour?: "numeric" | "2-digit" | undefined;
  minute?: "numeric" | "2-digit" | undefined;
  second?: "numeric" | "2-digit" | undefined;
}

export const ISOToLocaleString = (
  date: string | number,
  options?: IISOToLocale,
  locale?: string
) => {
  const defaultDateFormat: IISOToLocale = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  if (!!options === false) options = defaultDateFormat;
  if (!!locale === false) locale = "es-MX";
  return new Date(date).toLocaleString(locale, options);
};

export const getEarliestOpeningTime = (scheduleArray: any[]): string => {
  let earliestTime = moment();

  for (let i = 0; i < scheduleArray.length; i++) {
    if (!scheduleArray[i].openTime) {
      continue;
    }

    const currentTime = moment(
      scheduleArray[i].openTime,
      "HH:mm:ss.SSS"
    ).subtract(6, "hours");

    if (currentTime.hours() < earliestTime.hours()) {
      earliestTime = currentTime;
    }
  }

  return earliestTime.format("HH:mm");
};

export const getLatestClosingTime = (scheduleArray: any[]): string => {
  let latestTime = moment();

  for (let i = 0; i < scheduleArray.length; i++) {
    if (!scheduleArray[i].closeTime) {
      continue;
    }

    const currentTime = moment(
      scheduleArray[i].closeTime,
      "HH:mm:ss.SSS"
    ).subtract(6, "hours");

    if (currentTime.hours() > latestTime.hours()) {
      latestTime = currentTime;
    }
  }

  return latestTime.format("HH:mm");
};

export const subtractHalfHourIfHalf = (hour: string): string => {
  const [hours, minutes] = hour.split(":");
  let result = hour;

  if (parseInt(minutes, 10) === 30) {
    const newHour = parseInt(hours, 10);
    result = `${newHour.toString().padStart(2, "0")}:00`;
  }

  return result;
};

export const addHalfHourIfHalf = (hour: string): string => {
  const [hours, minutes] = hour.split(":");
  let result = hour;

  if (parseInt(minutes, 10) === 30) {
    const newHour = parseInt(hours, 10) + 1;
    result = `${newHour.toString().padStart(2, "0")}:00`;
  }

  return result;
};

export const checkIfPersonIsAdult = (date: Date): boolean => {
  if (!date) return false;
  const today = moment();
  const birthDate = moment(date);
  const years = today.diff(birthDate, "years");
  return years >= 18;
};

export const formatShortDate = (date: string | number | Date | null) => {
  if (date)
    return new Date(date).toLocaleDateString("es-MX", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
};

export const formatShortTime = (date: string | number | Date | null) => {
  if (date)
    return new Date(date).toLocaleTimeString("es-MX", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
};
