import React from "react";
import Icon from "@ant-design/icons";

const EditPencilSvg = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8047 5.17871L17.4521 11.008L11.8047 5.17871Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2169 4.75929C21.5926 5.14709 21.5926 5.76311 21.2169 6.15091L8.71367 19.0569L2.125 21L4.00748 14.1991L16.4666 1.33868C16.8595 0.933096 17.5101 0.933095 17.903 1.33868L21.2169 4.75929Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

const EditPencilIcon = (props: any) => (
  <Icon component={EditPencilSvg} {...props} />
);

export default EditPencilIcon;
