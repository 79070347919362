import { PackageCodeType, PackageDescType } from "shared";
/**
 * This function parse a number to currency format
 * @param price number
 * @returns string
 */
export const parseCurrency = (price: number): string =>
  new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 0,
  }).format(price);

/**
 * 
 * @param input string
 * @param capitalizeEachWord if true, each word of the given string will be capitalized
 * @returns 
 */
export const  capitalize = (input: string, capitalizeEachWord: boolean = false): string => {
  if (!input) return input;
  if (capitalizeEachWord) {
    return input.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  } else {
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
  }
}

export const tierCodeToDesc = (code: PackageCodeType | "") : PackageDescType => {
  const tier: Record<PackageCodeType, PackageDescType> = {
    'BASICO': 'Básico',
    'PLUS':  'Plus',
    'PREMIUM': 'Premium'
  };
  return tier[code] || "";
}