export function debounce(cb: any, delay = 500) {
  let timeout: any;

  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => cb(...args), delay);
  };
}

export function debounce2<F extends (...args: any[]) => any>(func: F, delay: number): (...args: Parameters<F>) => void {
  let timer: ReturnType<typeof setTimeout> | null;
  
  return function(this: ThisParameterType<F>, ...args: Parameters<F>) {
      if (timer) clearTimeout(timer);

      timer = setTimeout(() => {
          func.apply(this, args);
      }, delay);
  };
}


export function throttle(cb: any, delay = 500) {
  let shouldWait = false;
  let waitingArgs: any;
  const timeoutFunc = () => {
    if (waitingArgs === null) {
      shouldWait = false;
    } else {
      cb(...waitingArgs);
      waitingArgs = null;
      setTimeout(timeoutFunc, delay);
    }
  };

  return (...args: any) => {
    if (shouldWait) {
      waitingArgs = args;
      return;
    }

    cb(...args);
    shouldWait = true;

    setTimeout(timeoutFunc, delay);
  };
}
